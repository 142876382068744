/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  sipProfilelists: [],
  sipProfilelistsort: [],
  singlesipProfile: {},

  sipEndpointslists:[],
  singlesipEndpoints:[],

  gatewaylists: [],
  gatewaylistsort: [],
  singlegateway: {},

  trunklists:[],
  trunklistsort:[],
  singletrunk:{},

};

export default (state = initialState, action) => {
  switch (action.type) {
    //Sip Profile
    case "SIP_PROFILE_LISTS": {
      return {
        ...state,
        sipProfilelists: action.payload,
      };
    }
    case "SIP_PROFILE_LIST_SORT": {
      return {
        ...state,
        sipProfilelistsort: action.payload,
      };
    }
    case "SIP_PROFILE_SINGLE": {
      return {
        ...state,
        singlesipProfile: action.payload,
      };
    }

    case "DELETE_SIP_PROFILE": {
      return {
        ...state,
        sipProfilelist: [
          ...state.sipProfilelists?.filter((x) => x.id !== action.payload),
        ],
      };
    }
      //Sip Endpoints
      case "SIP_ENDPOINTS_LISTS": {
        return {
          ...state,
          sipEndpointslists: action.payload,
        };
      }
      case "SIP_ENDPOINTS_SINGLE": {
        return {
          ...state,
          singlesipEndpoints: action.payload,
        };
      }
  
      case "DELETE_SIP_ENDPOINTS": {
        return {
          ...state,
          sipEndpointslist: [
            ...state.sipEndpointslists?.filter((x) => x.id !== action.payload),
          ],
        };
      }
    //Gateway
    case "GATEWAY_LISTS": {
      return {
        ...state,
        gatewaylists: action.payload,
      };
    }
    case "GATEWAY_LIST_SORT": {
      return {
        ...state,
        gatewaylistsort: action.payload,
      };
    }
    case "GATEWAY_SINGLE": {
      return {
        ...state,
        singlegateway: action.payload,
      };
    }

    case "DELETE_GATEWAY": {
      return {
        ...state,
        gatewaylists: [
          ...state.gatewaylists?.filter((x) => x.id !== action.payload),
        ],
      };
    }

    //Trunks
    case "TRUNK_LISTS": {
      return {
        ...state,
        trunklists: action.payload,
      };
    }
    case "TRUNK_LIST_SORT": {
      return {
        ...state,
        trunklistsort: action.payload,
      };
    }
    case "TRUNK_SINGLE": {
      return {
        ...state,
        singletrunk: action.payload,
      };
    }

    case "DELETE_TRUNK": {
      return {
        ...state,
        trunklists: [
          ...state.trunklists?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
