import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import moment from "moment";
const hideColumns = [4];
const callhideColumns = [40];
const cfprohideColumns = [63];

const username = localStorage.getItem("username");
const url = new URL(window.location.href);
var pathSegments = url.pathname.split("/");
var tenantId = pathSegments[2];
const key = `tenant_${tenantId}`;
const selectedtenant = JSON.parse(localStorage.getItem(key) || "{}");

const inititalState = {
  auth: {
    authenticated: {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
      accessType: localStorage.getItem("accessType"),
      id: localStorage.getItem("id"),
      role: localStorage.getItem("role"),
      email: localStorage.getItem("email"),
      username: localStorage.getItem("username"),
      token_type: localStorage.getItem("token_type"),
      timezone: localStorage.getItem("timezones"),
      timezoneId: localStorage.getItem("timezoneId"),
    },
  },
  //user
  userReport: {
    uiReport: {
      user: window.location.pathname.includes("/view-tenant/")
        ? [
            selectedtenant?.tenant_first_name
              ? `${selectedtenant?.tenant_first_name} ${selectedtenant?.tenant_last_name}`
              : `${selectedtenant?.first_name || ""} ${
                  selectedtenant?.last_name || ""
                }`,
          ]
        : [username],
      timezone: localStorage.getItem("timezones"),
      time: [moment().startOf("day"), moment().endOf("day")],
    },
    activefilter: {},
    filter: [],
    columns: [],
    querycdr: [],
    count: 0,
    hidencolumns: hideColumns,
    timeprops: [],
    timechart: [],
    callgrid: {},
    insightgroup: [],
    insightattribute: [],
    insight: [],
    uiColumns: {
      contactSetting: [],
      userSetting: [],
      cfProSetting: [],
    },
    // uiColumns: {
    //   userSetting: [
    //     {
    //       id: 4,
    //       title: "Call Date",
    //       dataIndex: "calldate",
    //       sorter: false,
    //       fixed: false,
    //       width: 100,
    //       show: true,
    //     },
    //   ],
    //   cfProSetting: [
    //     {
    //       id: 63,
    //       title: "Call Date",
    //       dataIndex: "calldate",
    //       sorter: false,
    //       fixed: false,
    //       width: 100,
    //       show: true,
    //     },
    //   ],
    //   contactSetting: [
    //     {
    //       id: 40,
    //       title: "Call Date",
    //       dataIndex: "calldate",
    //       sorter: false,
    //       fixed: false,
    //       width: 100,
    //       show: true,
    //     },
    //   ],
    // },

    // cpontact-center
    calluiReport: {
      user: window.location.pathname.includes("/view-tenant/")
        ? [
            selectedtenant?.tenant_first_name
              ? `${selectedtenant?.tenant_first_name} ${selectedtenant?.tenant_last_name}`
              : `${selectedtenant?.first_name || ""} ${
                  selectedtenant?.last_name || ""
                }`,
          ]
        : [username],
      timezone: localStorage.getItem("timezones"),
      time: [moment().startOf("day"), moment().endOf("day")],
    },
    callactivefilter: {},
    callfilter: [],
    callcolumns: [],
    callquerycdr: [],
    callcount: 0,
    callhidencolumns: callhideColumns,
    calltimeprops: [],
    calltimechart: [],
    callgrids: {},
    callinsightgroup: [],
    callinsightattribute: [],
    callinsight: [],
    //cfpro
    cfprouiReport: {
      user: window.location.pathname.includes("/view-tenant/")
        ? [
            selectedtenant?.tenant_first_name
              ? `${selectedtenant?.tenant_first_name} ${selectedtenant?.tenant_last_name}`
              : `${selectedtenant?.first_name || ""} ${
                  selectedtenant?.last_name || ""
                }`,
          ]
        : [username],
      timezone: localStorage.getItem("timezones"),
      time: [moment().startOf("day"), moment().endOf("day")],
    },
    cfproactivefilter: {},
    cfprofilter: [],
    cfprocolumns: [],
    cfproquerycdr: [],
    cfprocount: 0,
    cfprohidencolumns: cfprohideColumns,
    cfprotimeprops: [],
    cfprotimechart: [],
    cfprogrids: {},
    cfproinsightgroup: [],
    cfproinsightattribute: [],
    cfproinsight: [],
  },
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
