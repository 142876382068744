import {
  ADD_TO_QUICK_ACCESS,
  REMOVE_FROM_QUICK_ACCESS,
  REORDER_QUICK_ACCESS,
} from "../actions/actionQuickAccess";
import { serializedIcons } from "../../AntdSidebar/iconsSerializer";

const initialState = {
  quickAccess: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_QUICK_ACCESS: {
      const exists = state.quickAccess.some(
        (item) => item.key === action.payload.key
      );
      if (exists) {
        return state;
      }
      return {
        ...state,
        quickAccess: [...state.quickAccess, action.payload],
      };
    }

    case REMOVE_FROM_QUICK_ACCESS: {
      return {
        ...state,
        quickAccess: state.quickAccess.filter(
          (item) => item.key !== action.payload.key
        ),
      };
    }
    case REORDER_QUICK_ACCESS: {
      return {
        ...state,
        quickAccess: action.payload,
      };
    }

    default:
      return state;
  }
};
