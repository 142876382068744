/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  groupblacklist: [],
  groupblacklists: [],
  groupblacklistsingle: [],
  blacklist: [],
  blacklists: [],
  blacklistsingle: [],
  buyDidList: [],
  phonenumber: [],
  phonenumbersingle: [],
  DIDList:[],
  DIDSingle:[],
  phonenumbers: [],
  phonenumberView: { filter: [], list: [] },
  blockPhonenumbers: [],
  blockPhonenumberView: { filter: [], list: [] },
  blockGroupPhonenumbers: [],
  blockGroupPhonenumberView: { filter: [], list: [] },
  didListView:[],
  DIDView: { filter:[], list:[] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GROUP_BLACKLIST": {
      return {
        ...state,
        groupblacklist: action.payload,
      };
    }

    case "GROUP_BLACKLISTS": {
      return {
        ...state,
        groupblacklists: action.payload,
      };
    }
    case "GROUP_BLACKLIST_SINGLE": {
      return {
        ...state,
        groupblacklistsingle: action.payload,
      };
    }
    case "DELETE_BLACKLIST_GROUP": {
      return {
        ...state,
        groupblacklists: [
          ...state.groupblacklists.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }

    case "BLACKLIST": {
      return {
        ...state,
        blacklist: action.payload,
      };
    }

    case "BLACKLISTS": {
      return {
        ...state,
        blacklists: action.payload,
      };
    }
    case "BLACKLIST_SINGLE": {
      return {
        ...state,
        singleblacklist: action.payload,
      };
    }
    case "DELETE_BLACKLIST": {
      return {
        ...state,
        blockPhonenumbers: [
          ...state.blockPhonenumbers?.results?.filter((x) => x.id !== action.payload),
        ],
      };
    }

    case "PHONENUMBER": {
      return {
        ...state,
        phonenumber: action.payload,
      };
    }
    case "DIDS": {
      return {
        ...state,
        didnumber: action.payload,
      };
    }
    case "PHONENUMBER_SINGLE": {
      return {
        ...state,
        phonenumbersingle: action.payload,
      };
    }
    case "DELETE_PHONENUMBER": {
      return {
        ...state,
        phonenumbers: [
          ...state.phonenumbers.results?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "GET_PHONENUMBER_VIEW": {
      return {
        ...state,
        phonenumberView: action.payload,
      };
    }
    case "BUY_DID_LIST": {
      return {
        ...state,
        buyDidList: action.payload,
      };
    }
    case "PHONENUMBERS": {
      return {
        ...state,
        phonenumbers: action.payload,
      };
    }
    case "GET_BLOCK_PHONENUMBER_VIEW": {
      return {
        ...state,
        blockPhonenumberView: action.payload,
      };
    }
    case "BLOCK_PHONENUMBERS": {
      return {
        ...state,
        blockPhonenumbers: action.payload,
      };
    }
    case "GET_BLOCK_GROUP_PHONENUMBER_VIEW": {
      return {
        ...state,
        blockGroupPhonenumberView: action.payload,
      };
    }
    case "BLOCK_GROUP_PHONENUMBERS": {
      return {
        ...state,
        blockGroupPhonenumbers: action.payload,
      };
    }
    case "GET_DID_VIEW": {
      return {
        ...state,
        DIDView: action.payload,
      };
    }
    case "DID_VIEW": {
      return {
        ...state,
        didListView: action.payload,
      };
    }
    case "DID": {
      return {
        ...state,
        DIDList: action.payload,
      };
    }
    case "DID_SINGLE": {
      return {
        ...state,
        DIDsingle: action.payload,
      };
    }
    // case "DELETE_DID": {
    //   return {
    //     ...state,
    //     DIDList: [
    //       ...state.DIDList.results?.filter((x) => x.id !== action.payload),
    //     ],
    //   };
    // }
    default: {
      return state;
    }
  }
};
