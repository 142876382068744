/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  callergrouplists: [],
  callergrouplist: [],
  callergroupsingle: {},
  cidNumber: [],
  singlecidNumber: {},
  calleroutbound: [],
  verifiedlist: [],
  singleverify: {},
  callerverified: [],
  callergroupsearch: {},
  callerngroupsearch: {},
  callerGroupView: { filter: [], list: [] },
  callerGroupListByFilters: [],
  cidNumberView: { filter: [], list: [] },
  cidNumberListByFilters: [],
  virtualCIDView: { filter: [], list: [] },
  virtualCIDListByFilters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Caller group reducer
    case "CALLER_GROUP_LISTS": {
      return {
        ...state,
        callergrouplists: action.payload,
      };
    }
    case "CALLER_GROUP_LIST": {
      return {
        ...state,
        callergrouplist: action.payload,
      };
    }
    case "CALLER_GROUP_SINGLE": {
      return {
        ...state,
        callergroupsingle: action.payload,
      };
    }

    case "DELETE_CALLER_GROUP": {
      return {
        ...state,
        callergrouplist: [
          ...state.callergrouplist.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "GET_CALLER_GROUP_FILTERS": {
      return {
        ...state,
        callerGroupView: action.payload,
      };
    }
    case "GET_CALLER_GROUP_LIST_BY_FILTERS": {
      return {
        ...state,
        callerGroupListByFilters: action.payload,
      };
    }

    //cidNumber reducer
    case "CID_NUMBER": {
      return {
        ...state,
        cidNumber: action.payload,
      };
    }
    case "CID_SINGLE": {
      return {
        ...state,
        singlecidNumber: action.payload,
      };
    }
    case "CALLER_OUTBOUND": {
      return {
        ...state,
        calleroutbound: action.payload,
      };
    }
    case "DELETE_CALLER_OUTBOUND": {
      return {
        ...state,
        calleroutbound: [
          ...state.calleroutbound.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "CALLERID_GROUP_SEARCH": {
      return {
        ...state,
        callergroupsearch: action.payload,
      };
    }
    case "CALLERID_NGROUP_SEARCH": {
      return {
        ...state,
        callerngroupsearch: action.payload,
      };
    }
    case "GET_CID_NUMBER_FILTERS": {
      return {
        ...state,
        cidNumberView: action.payload,
      };
    }
    case "GET_CID_NUMBER_LIST_BY_FILTERS": {
      return {
        ...state,
        cidNumberListByFilters: action.payload,
      };
    }
    //verified cidNumber
    case "VIRTUAL_CID": {
      return {
        ...state,
        verifiedlist: action.payload,
      };
    }
    case "VIRTUAL_SINGLE": {
      return {
        ...state,
        singleverify: action.payload,
      };
    }
    case "VERIFIED_CALLER_ID": {
      return {
        ...state,
        callerverified: action.payload,
      };
    }
    case "DELETE_VIRTUAL_CALLER": {
      return {
        ...state,
        callerverified: [
          ...state.callerverified.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "GET_VIRTUAL_CID_FILTERS": {
      return {
        ...state,
        virtualCIDView: action.payload,
      };
    }
    case "GET_VIRTUAL_CID_LIST_BY_FILTERS": {
      return {
        ...state,
        virtualCIDListByFilters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
