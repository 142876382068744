/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  userList: [],
  companyList: [],
  tenantList: [],
  customerList: [],
  role: [],
  destType: [],
  clidType: [],
  location: [],
  locationsingle: [],
  strategyType: [],
  customerSingle: [],
  locationView: { filter: [], list: [] },
  locationListByFilters: [],
  tenantView: { filter: [], list: [] },
  tenantListByFilters: [],
  userView: { filter: [], list: [] },
  userListByFilters: [],
  providerlists: [],
  singleprovider: {},
  providerView: { filter: [], list: [] },
  providerListByFilters: [],
  countryList:[],
  currencyList:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ROLE_LIST": {
      return {
        ...state,
        role: action.payload,
      };
    }
    case "USER_LIST": {
      return {
        ...state,
        userList: action.payload,
      };
    }
    case "COMPANY_LIST": {
      return {
        ...state,
        companyList: action.payload,
      };
    }
    case "TENANT_LIST": {
      return {
        ...state,
        tenantList: action.payload,
      };
    }
    case "ENDUSER_LIST": {
      return {
        ...state,
        customerList: action.payload,
      };
    }
    case "ENDUSER_SINGLE": {
      return {
        ...state,
        customerSingle: action.payload,
      };
    }
    case "DELETE_COMPANY": {
      return {
        ...state,
        companyList: [
          ...state.companyList.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "DELETE_TENANT": {
      return {
        ...state,
        tenantList: [
          ...state.tenantList.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "GET_TENANT_FILTERS": {
      return {
        ...state,
        tenantView: action.payload,
      };
    }
    case "TENANT_LIST_BY_FILTER": {
      return {
        ...state,
        tenantListByFilters: action.payload,
      };
    }
    case "GET_USER_FILTERS": {
      return {
        ...state,
        userView: action.payload,
      };
    }
    case "USER_LIST_BY_FILTER": {
      return {
        ...state,
        userListByFilters: action.payload,
      };
    }
    case "DELETE_ENDUSER": {
      return {
        ...state,
        customerList: [
          ...state.customerList.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "DEST_TYPE": {
      return {
        ...state,
        destType: action.payload,
      };
    }
    case "CLID_TYPE": {
      return {
        ...state,
        clidType: action.payload,
      };
    }

    case "STRATEGY": {
      return {
        ...state,
        strategyType: action.payload,
      };
    }
    case "LOCATION": {
      return {
        ...state,
        location: action.payload,
      };
    }
    case "GET_LOCATION_FILTERS": {
      return {
        ...state,
        locationView: action.payload,
      };
    }
    case "LOCATIONS_LIST_BY_FILTER": {
      return {
        ...state,
        locationListByFilters: action.payload,
      };
    }
    case "LOCATION_SINGLE": {
      return {
        ...state,
        locationsingle: action.payload,
      };
    }

    case "DELETE_LOCATION": {
      return {
        ...state,
        location: [...state.location.filter((x) => x.id !== action.payload)],
      };
    }
    //Provider
    case "PROVIDER_LISTS": {
      return {
        ...state,
        providerlists: action.payload,
      };
    }
    case "PROVIDER_SINGLE": {
      return {
        ...state,
        singleprovider: action.payload,
      };
    }

    case "DELETE_PROVIDER": {
      return {
        ...state,
        providerlists: [
          ...state.providerlists?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "GET_PROVIDER_FILTERS": {
      return {
        ...state,
        providerView: action.payload,
      };
    }
    case "PROVIDER_LIST_BY_FILTER": {
      return {
        ...state,
        providerListByFilters: action.payload,
      };
    }

    case "COUNTRY_LISTS": {
      return {
        ...state,
        countryList: action.payload,
      };
    }
    case "CURRENCY_LISTS": {
      return {
        ...state,
        currencyList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
