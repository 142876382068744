import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import PageLoading from "./components/PageLoading";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";
import FallBackComponent from "./components/Fallback";
import { CompanyProvider } from "./pages/userpersonal/companyContext";
import { CountProvider } from "./context/liveContext";
import "@ant-design/pro-table/dist/table.css";

// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const baseUrl = "/";
const history = createBrowserHistory({ basename: baseUrl });
const errorHandler = (error, errorInfo) => {
  console.log(error, errorInfo);
};

ReactDOM.render(
  //<React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <ConfigProvider locale={enUS}>
        <CompanyProvider>
          {/* <ErrorBoundary
          FallbackComponent={FallBackComponent}
          onError={errorHandler}
        > */}
          <CountProvider>
            <App />
          </CountProvider>
        </CompanyProvider>
        {/* </ErrorBoundary> */}
        <PageLoading />
      </ConfigProvider>
    </Router>
  </Provider>,

  //</React.StrictMode>
  document.getElementById("root")
);
