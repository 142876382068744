import DashboardIcon from "@material-ui/icons/Dashboard";
import DialpadIcon from "@material-ui/icons/Dialpad";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import MusicVideoIcon from "@material-ui/icons/MusicVideo";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ContactsIcon from "@material-ui/icons/Contacts";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PortraitIcon from "@material-ui/icons/Portrait";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import DialerSipIcon from "@material-ui/icons/DialerSip";
import PaymentIcon from "@material-ui/icons/Payment";
import MapIcon from "@material-ui/icons/Map";
import DirectionsIcon from "@material-ui/icons/Directions";
import { FiPhoneCall } from "react-icons/fi";
import { LuNetwork } from "react-icons/lu";
import { TfiWallet } from "react-icons/tfi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoPeopleOutline } from "react-icons/io5";
import { FiHome } from "react-icons/fi";
/**
 * Serialize an icon by returning its key.
 * @param {JSX.Element} icon - The icon React element to serialize.
 * @returns {string|null} - The key of the icon or null if not found.
 */
export const serializeIcon = (icon) => {
  const key = Object.entries(iconMap).find(
    ([_, value]) => value.type === icon.type
  )?.[0];
  return key || null;
};

/**
 * Deserialize an icon by returning the corresponding React component.
 * @param {string} key - The key of the icon to deserialize.
 * @returns {JSX.Element|null} - The React element or null if not found.
 */
export const deserializeIcon = (key) => iconMap[key] || null;

const iconMap = {
  FiHome: <FiHome />,
  IoPeopleOutline: <IoPeopleOutline />,
  HiOutlineDocumentReport: <HiOutlineDocumentReport />,
  TfiWallet: <TfiWallet />,
  LuNetwork: <LuNetwork />,
  DashboardIcon: <DashboardIcon className="" />,
  DialpadIcon: <DialpadIcon className="" />,
  PermIdentityIcon: <PermIdentityIcon className="" />,
  PeopleAltIcon: <PeopleAltIcon className="" />,
  AddLocationIcon: <AddLocationIcon className="" />,
  SettingsVoiceIcon: <SettingsVoiceIcon className="" />,
  GroupWorkIcon: <GroupWorkIcon className="" />,
  PhoneForwardedIcon: <PhoneForwardedIcon className="" />,
  AudiotrackIcon: <AudiotrackIcon className="" />,
  MusicVideoIcon: <MusicVideoIcon className="" />,
  GraphicEqIcon: <GraphicEqIcon className="" />,
  GroupAddIcon: <GroupAddIcon className="" />,
  ContactsIcon: <ContactsIcon className="" />,
  NotInterestedIcon: <NotInterestedIcon className="" />,
  PortraitIcon: <PortraitIcon className="" />,
  ContactPhoneIcon: <ContactPhoneIcon className="" />,
  DynamicFeedSharpIcon: <DynamicFeedSharpIcon className="" />,
  HeadsetMicIcon: <HeadsetMicIcon className="" />,
  DialerSipIcon: <DialerSipIcon className="" />,
  PaymentIcon: <PaymentIcon className="" />,
  MapIcon: <MapIcon className="" />,
  DirectionsIcon: <DirectionsIcon className="" />,
  FiPhoneCall: <FiPhoneCall className="" />,
};

// Serialize icons after the map is defined
const serializedIcons = Object.keys(iconMap).reduce((acc, iconName) => {
  acc[iconName] = serializeIcon(iconMap[iconName]);
  return acc;
}, {});

export { serializedIcons };
export default iconMap;
