/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  sipDevice: [],
  sipDevicesort: [],
  singlesipDevice: {},
  sipDevicesView: { filter: [], list: [] },
  sipDevicesListByFilters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SIP_DEVICES": {
      return {
        ...state,
        sipDevice: action.payload,
      };
    }
    case "SIP_DEVICES_SORT": {
      return {
        ...state,
        sipDevicesort: action.payload,
      };
    }
    case "SIP_DEVICES_SINGLE": {
      return {
        ...state,
        singlesipDevice: action.payload,
      };
    }

    case "DELETE_SIP_DEVICES": {
      return {
        ...state,
        sipDevicesort: [
          ...state.sipDevicesort?.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "GET_SIP_DEVICES_FILTERS": {
      return {
        ...state,
        sipDevicesView: action.payload,
      };
    }
    case "GET_SIP_DEVICES_LIST_BY_FILTERS": {
      return {
        ...state,
        sipDevicesListByFilters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
