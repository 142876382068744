const initialState = { data: {}, isLoading: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_UISETTING": {
      return { ...state, data: action.payload };
    }
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};
