import { current } from "@reduxjs/toolkit";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  View: {},
  List: {},
  currentFilter: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "VIEW": {
      const { pageName, value } = action.payload;
      return {
        ...state,
        View: { ...state.View, [pageName]: value },
      };
    }
    case "LIST": {
      const { pageName, value } = action.payload;

      return {
        ...state,
        List: { ...state.List, [pageName]: value },
      };
    }
    case "CURRENT_FILTER": {
      const { pageName, value } = action.payload;
      return {
        ...state,
        currentFilter: { [pageName]: value },
      };
    }
    default: {
      return state;
    }
  }
};
