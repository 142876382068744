/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  rateGrouplists: [],
  rateGrouplistsort: [],
  singlerateGroup: {},

  terminationlists: [],
  terminationlistsort: [],
  singletermination: {},

  orginationlists:[],
  orginationlistsort:[],
  singleorginationlists:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Rate Group
    case "RATEGROUP_LISTS": {
      return {
        ...state,
        rateGrouplists: action.payload,
      };
    }
    case "RATEGROUP_LIST_SORT": {
      return {
        ...state,
        rateGrouplistsort: action.payload,
      };
    }
    case "RATEGROUP_SINGLE": {
      return {
        ...state,
        singlerateGroup: action.payload,
      };
    }

    case "DELETE_RATEGROUP": {
      return {
        ...state,
        rateGrouplists: [
          ...state.rateGrouplists?.filter((x) => x.id !== action.payload),
        ],
      };
    }

    //Termination Rates
    case "TERMINATION_LISTS": {
      return {
        ...state,
        terminationlists: action.payload,
      };
    }
    case "TERMINATION_LIST_SORT": {
      return {
        ...state,
        terminationlistsort: action.payload,
      };
    }
    case "TERMINATION_SINGLE": {
      return {
        ...state,
        singletermination: action.payload,
      };
    }

    case "DELETE_TERMINATION": {
      return {
        ...state,
        terminationlists: [
          ...state.terminationlists?.filter((x) => x.id !== action.payload),
        ],
      };
    }

    //Orgination Rates
    case "ORIGINATION_LISTS": {
      return {
        ...state,
        orginationlists: action.payload,
      };
    }
    case "ORIGINATION_LIST_SORT": {
      return {
        ...state,
        orginationlistsort: action.payload,
      };
    }
    case "ORIGINATION_SINGLE": {
      return {
        ...state,
        singleorgination: action.payload,
      };
    }

    case "DELETE_ORIGINATION": {
      return {
        ...state,
        orginationlists: [
          ...state.orginationlists?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
