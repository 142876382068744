// themeReducer.js

const storedTheme = localStorage.getItem("theme") || "light";

const initialState = {
  theme: storedTheme,
};

// Action Types
const TOGGLE_THEME = "TOGGLE_THEME";

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      const newTheme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      return { ...state, theme: newTheme };

    default:
      return state;
  }
};

export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

export default themeReducer;
