/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  target: [],
  targetsort: [],
  singletarget: {},
  callforwarding: [],
  callforwardingsort: [],
  singlecallforwarding: {},
  targetmapper: [],
  targetmappersort: [],
  singletargetmapper: {},
  targetinpro: [],
  targetnotpro: [],
  targetView: { filter: [], list: [] },
  targetListByFilters: [],
  callForwardingView: { filter: [], list: [] },
  callForwardingListByFilters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    //reducer for target
    case "TARGET": {
      return {
        ...state,
        target: action.payload,
      };
    }
    case "TARGET_SORT": {
      return {
        ...state,
        targetsort: action.payload,
      };
    }
    case "TARGET_SINGLE": {
      return {
        ...state,
        singletarget: action.payload,
      };
    }

    case "DELETE_TARGET": {
      return {
        ...state,
        targetsort: [
          ...state.targetsort?.results?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    case "GET_TARGET_FILTERS": {
      return {
        ...state,
        targetView: action.payload,
      };
    }
    case "GET_TARGET_LIST_BY_FILTERS": {
      return {
        ...state,
        targetListByFilters: action.payload,
      };
    }

    // reducer for cfpro

    case "CALL_FORWARDING": {
      return {
        ...state,
        callforwarding: action.payload,
      };
    }
    case "CALL_FORWARDING_SORT": {
      return {
        ...state,
        callforwardingsort: action.payload,
      };
    }
    case "CALL_FORWARDING_SINGLE": {
      return {
        ...state,
        singlecallforwarding: action.payload,
      };
    }
    case "DELETE_CALL_FORWARDING": {
      return {
        ...state,
        callforwardingsort: [
          ...state.callforwardingsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "GET_CALL_FORWARDING_FILTERS": {
      return {
        ...state,
        callForwardingView: action.payload,
      };
    }
    case "GET_CALL_FORWARDING_LIST_BY_FILTERS": {
      return {
        ...state,
        callForwardingListByFilters: action.payload,
      };
    }

    //reducer for target mapper

    case "TARGET_MAPPER": {
      return {
        ...state,
        targetmapper: action.payload,
      };
    }
    case "TARGET_MAPPER_SORT": {
      return {
        ...state,
        targetmappersort: action.payload,
      };
    }
    case "TARGET_MAPPER_SINGLE": {
      return {
        ...state,
        singletargetmapper: action.payload,
      };
    }
    case "TARGET_PRO": {
      return {
        ...state,
        targetinpro: action.payload,
      };
    }
    case "TARGET_NOT_PRO": {
      return {
        ...state,
        targetnotpro: action.payload,
      };
    }
    case "DELETE_TARGET_MAPPER": {
      return {
        ...state,
        targetinpro: [
          ...state.targetinpro?.filter((x) => x.id !== action.payload),
        ],
      };
    }

    default: {
      return state;
    }
  }
};
