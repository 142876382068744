export const ADD_TO_QUICK_ACCESS = "ADD_TO_QUICK_ACCESS";
export const REMOVE_FROM_QUICK_ACCESS = "REMOVE_FROM_QUICK_ACCESS";
export const REORDER_QUICK_ACCESS = "REORDER_QUICK_ACCESS";
export const addToQuickAccess = (item) => {
  return {
    type: ADD_TO_QUICK_ACCESS,
    payload: item,
  };
};

export const removeFromQuickAccess = (key) => {
  return {
    type: REMOVE_FROM_QUICK_ACCESS,
    payload: { key },
  };
};

export const reorderQuickAccess = (quickAccess) => {
  return {
    type: REORDER_QUICK_ACCESS,
    payload: quickAccess,
  };
};
